<template>
  <div class="card-holder">
    <el-card>
      <h3>{{ $t("OTPHandlingPageLabel") }}</h3>
      <el-row>
        <el-col :span="5" :offset="1">
          <el-input
            :placeholder="$t('OTPHandlingPageFieldPlaceholderPhoneNumber')"
            v-model="phoneNumber"
            clearable
            type="number"
          >
          </el-input>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-select
            :placeholder="$t('OTPHandlingPageFieldPlaceholderType')"
            v-model="otpType"
            clearable
          >
            <el-option
              v-for="item in otpConfigs"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-button
            class="login-button"
            type="success"
            block
            @click="generateButtonClicked"
            :disabled="!phoneNumber || !otpType"
          >
            {{ $t("OTPHandlingPageBtnLabelGenerate") }}
          </el-button>
        </el-col>

        <el-col :span="5" :offset="1">
          <el-tag type="info" effect="plain" v-if="generatedString">
            {{ generatedString }}
          </el-tag>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <h3>Get OTP By phone number</h3>
      <el-row>
        <el-col :span="5" :offset="1">
          <el-input
            placeholder="phone number"
            v-model="getPhoneNumber"
            clearable
            type="number"
          >
          </el-input>
        </el-col>
     
        <el-col :span="5" :offset="1">
          <el-button
            class="login-button"
            type="success"
            block
            @click="getOtpButtonClicked"
            :disabled="!getPhoneNumber"
          >
           Get OTP
          </el-button>
        </el-col>

        <el-col :span="5" :offset="1">
          <el-tag type="info" effect="plain" v-if="resultGetOtp">
            {{ resultGetOtp }}
          </el-tag>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { adminActions } from "@/services";

export default {
  name: "OtpHandlingPage",
  data() {
    return {
      otpConfigs: [],
      phoneNumber: null,
      otpType: null,
      generatedString: null,
      getPhoneNumber: null,
      resultGetOtp: null
    };
  },
  methods: {
    async generateButtonClicked() {
      try {
        const invalidDataFlag = this.validateData();
        if (invalidDataFlag) return;
        const apiObject = {
          phone: this.phoneNumber || "01095863591",
          type: this.otpType || "login",
        };
        const response = await adminActions.generateOTP(apiObject);
        if (response && response.status == 200) {
          console.log("response", response);
          const generatedOtp = response.data.otp;
          this.generatedString = generatedOtp;
        }
      } catch (error) {
        console.log("generateOTPerror", error);
        const errorMessage = this.errorMessageExtractor(error);
        this.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    async getOtpButtonClicked() {
      try {
        const invalidDataFlag = this.validateData();
        if (invalidDataFlag) return;
        const phone =  this.getPhoneNumber;
        const response = await adminActions.getOTPByPhoneNumber(phone);
        if (response && response.status == 200) {
          const getResultOtp = response?.data?.data?.otp;
          this.resultGetOtp = getResultOtp;
        }
      } catch (error) {
        const errorMessage = this.errorMessageExtractor(error);
        this.popupMessageWrapper(errorMessage, "error", 3500);
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExists =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      let finalErrorMessage = this.$t(
        "RetailerWalletsPageWalletDefaultErrorMessage"
      );
      if (errorMessageExists) {
        finalErrorMessage = errorObject.response.data.message;
      }
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      return finalErrorMessage;
    },
    validateData() {
      let returnValue = false;
      const phoneNumberValue = this.phoneNumber || this.getPhoneNumber;
      if (!phoneNumberValue) {
        this.popupMessageWrapper("No phone number entered", "error", 1500);
        returnValue = true;
        return returnValue;
      }
      if (phoneNumberValue.length > 11 || phoneNumberValue.length < 11) {
        this.popupMessageWrapper(
          "Invalid phone number (number length should be 11)",
          "error",
          2000
        );
        returnValue = true;
        return returnValue;
      }
      const typeValue = this.otpType;
       if (!typeValue &&  !this.getPhoneNumber) {
        this.popupMessageWrapper("No type chosen", "error", 1500);
        returnValue = true;
        return returnValue;
      }
      return returnValue;
    },
  },
  computed: {
    isRTL() {
      return this.$store.state.isRTL;
    },
  },
  async mounted() {
    const response = await adminActions.getConfig("otpconfig");
    const configData = response.data.config;
    if (configData.otpConfig) {
      const configKeyValues = Object.keys(configData.otpConfig);
      this.otpConfigs = configKeyValues;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  width: 800px;
}
.el-select {
  width: 100%;
}
.card-holder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px
}
</style>
